@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-regular.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-bold.woff2')
    format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-regular-italic.woff2')
    format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-bold-italic.woff2')
    format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-medium.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-medium-italic.woff2')
    format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-black.woff2')
    format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-black-italic.woff2')
    format('woff2');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-light.woff2')
    format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-light-italic.woff2')
    format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-extralight.woff2')
    format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-extralight-italic.woff2')
    format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-thin.woff2')
    format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-thin-italic.woff2')
    format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-extrabold.woff2')
    format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-extrabold-italic.woff2')
    format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-thin.woff2')
    format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'National2Condensed';
  src: url('../public/fonts/national-2-condensed/national-2-condensed-thin-italic.woff2')
    format('woff2');
  font-weight: 100;
  font-style: italic;
}

:root {
  --color-primary: #000a8c;
  --color-blue-secondary: #3269c8;
  --color-primary-hover: #4cb98d;
  --color-green-light: #e7ffe8;
  --color-grey-light: #f9f9f9;
  --color-grey-dark: rgba(0, 0, 0, 0.4);
  --color-grey-divider: #f1f1f1;
  --color-font: #333333;
  --color-green: #1caf5e;
  --color-yellow: #f7b500;
  --color-red: #f14b44;
  --color-blue: #007fff;
  --color-chip: #e1eefa;
  --color-background: #f0f2f0;
  --color-chip-selected: #b3cff5;
  --grid-width: 940px;
  --top-banner-height: 180px;

  --fc-button-bg-color: #e6f3fa;
  --fc-button-hover-bg-color: #e6f3fa;
  --fc-button-active-bg-color: #e6f3fa;
  --fc-button-text-color: #007fff;
  --fc-button-border-color: #007fff;
  --fc-button-hover-border-color: #007fff;
  --fc-today-bg-color: #e6f3fa;

  --content-max-width: 1520px;
  --content-padding-xl: 0px 100px;
  --content-padding-lg: 0px 80px;
  --content-padding-md: 0px 16px;
  --content-padding-sm: 0px 16px;
  --content-padding-xs: 0px 16px;

  --event-upsert-max-width: 1280px;
}

.fc h2.fc-toolbar-title {
  font-size: 1.4em;
}

.fc .fc-col-header-cell-cushion {
  text-transform: capitalize;
  color: var(--color-grey-dark);
  font-size: 1em;
}

.fc .fc-day-today .fc-col-header-cell-cushion {
  color: var(--color-blue);
}

body {
  color: var(--color-font);
  /* Setting padding top to a really small value to avoid margin collapsing, where the body is moved down */
  padding: 0.1px 0 0 0;
  margin: 0;
  font-size: 16px;
  background-color: #f0f2f0;
  font-family:
    'Inter',
    'Poppins',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  font-weight: normal;
}

h6 {
  font-size: 0.77em;
}

h5 {
  font-size: 1em;
}

h4 {
  font-size: 1.33em;
}

h3 {
  font-size: 1.77em;
}

h2 {
  font-size: 2.22em;
}

h1 {
  font-size: 2.66em;
}

p {
  font-size: inherit;
  letter-spacing: 0.3px;
  line-height: 1.4;
}

label {
  font-size: 0.88em;
  color: #666666;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .intercom-lightweight-app-launcher {
    left: 20px;
    bottom: 30px !important;
  }
}
