.video-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.video-wrapper {
  width: 100%;
  height: 100%;
}
