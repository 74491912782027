.MuiTiptap-FieldContainer-root .MuiTiptap-MenuBar-nonSticky {
  background-color: white;
  border: none;
}

.MuiTiptap-FieldContainer-root
  .MuiTiptap-RichTextContent-root.MuiTiptap-RichTextField-content {
  padding: 0;
}

.MuiTiptap-RichTextField-content ul,
ol {
  padding-left: 24px;
}

.MuiTiptap-RichTextField-content h1,
h2 {
  margin-bottom: 16px;
}
